const createKeyEnum = data =>
  Object.freeze({
    ...data,

    getByKey(key) {
      let dataKeys = {}
      Object.entries(data).forEach(([entryKey, { key }]) => {
        dataKeys[key] = this[entryKey]
      })
      return dataKeys[key]
    },

    getValues() {
      return Object.values(data)
    },
  })

const flashing = true
const INIT = { key: 'Init', class: 'bg-cardHover', text: 'Init' }
const DRAFT = { key: 'Draft', class: 'bg-gray-500', text: 'Draft' }
const PENDING = {
  key: 'Pending',
  class: 'bg-cardHover shadow-md shadow-cardHover',
  text: 'Pending',
  flashing,
}
const IN_QUEUE = {
  key: 'In queue',
  class: 'bg-info shadow-md shadow-info',
  text: 'In queue',
  flashing,
}
const EXECUTION = {
  key: 'Execution',
  class: 'bg-info shadow-md shadow-info',
  text: 'Execution',
  flashing,
}
const ABORTING = {
  key: 'Aborting',
  class: 'bg-card shadow-md shadow-card',
  text: 'Aborting',
  flashing,
}
const ABORT_FAILED = { key: 'Abort failed', class: 'bg-card', text: 'Abort failed' }
const ABORTED = { key: 'Aborted', class: 'bg-card', text: 'Aborted' }
const TIMEOUT_ERROR = { key: 'Timeout error', class: 'bg-error', text: 'Timeout error' }
const SUCCESS = { key: 'Success', class: 'bg-success', text: 'Success' }
const ERROR = { key: 'Error', class: 'bg-error', text: 'Error' }
const FAILURE = { key: 'Failure', class: 'bg-error', text: 'Failure' }
const DEFAULT = { key: 'default', class: 'bg-cardHover', text: '' }
const NOT_EXECUTED = { key: 'Not executed', class: 'bg-cardHover', text: 'Not Executed' }

const ONLINE = { key: 'Online', class: 'bg-success', text: 'Online' }
const OFFLINE = { key: 'Offline', class: 'bg-error', text: 'Offline' }
const AUTH_ERROR = { key: 'AuthError', class: 'bg-error', text: 'Auth Error' }
const INVALID_STATUS_CODE = {
  key: 'InvalidStatusCode',
  class: 'bg-error',
  text: 'Invalid Status Code',
}
const MAINTENANCE = { key: 'Maintenance', class: 'bg-warning', text: 'Maintenance', flashing }
const READY = { key: 'Ready', class: 'bg-success', text: 'Ready' }
const BUSY = { key: 'Busy', class: 'bg-info', text: 'Busy', flashing }
const NOT_CONNECTED = { key: 'Not connected', class: 'bg-red-200', text: 'Not connected' }
const NOT_AVAILABLE = { key: 'N/A', class: '!hidden', text: 'Not available' }

export const NodeStatus = createKeyEnum({
  NOT_CONNECTED,
  ONLINE,
  OFFLINE,
  AUTH_ERROR,
  INVALID_STATUS_CODE,
  MAINTENANCE,
})

export const DeviceStatus = createKeyEnum({ ONLINE, OFFLINE, NOT_AVAILABLE })

export const ActivityStatus = createKeyEnum({ READY, BUSY, OFFLINE })

export const TaskStatus = createKeyEnum({
  DRAFT,
  INIT,
  IN_QUEUE,
  PENDING,
  EXECUTION,
  ABORTING,
  SUCCESS,
  FAILURE,
  ERROR,
  ABORTED,
  ABORT_FAILED,
  TIMEOUT_ERROR,
  DEFAULT,
})

export const TaskActiveStatuses = [
  TaskStatus.INIT.key,
  TaskStatus.PENDING.key,
  TaskStatus.EXECUTION.key,
  TaskStatus.ABORTING.key,
]
export const TaskFinalStatuses = [
  TaskStatus.SUCCESS.key,
  TaskStatus.FAILURE.key,
  TaskStatus.ERROR.key,
  TaskStatus.ABORTED.key,
  TaskStatus.ABORT_FAILED.key,
  TaskStatus.TIMEOUT_ERROR.key,
]

export const TaskStepStatus = createKeyEnum({
  NOT_EXECUTED,
  EXECUTION,
  SUCCESS,
  FAILURE,
  ERROR,
  ABORTED,
  TIMEOUT_ERROR,
})

export const TaskStepFinalStatuses = [
  TaskStatus.SUCCESS.key,
  TaskStatus.ERROR.key,
  TaskStatus.ABORTED.key,
]

export const TaskPackageType = Object.freeze({
  LOG: 'log',
  PACKAGE: 'package',
  RESULT: 'result',
  REPORT: 'report',
})

export const ComparisonFilters = Object.freeze({
  GE: 'ge',
  GT: 'gt',
  LE: 'le',
  LT: 'lt',
  NE: 'ne',
  EQ: 'eq',
})

export const TestToolType = Object.freeze({
  PYTEST: 'pytest',
  ROBOT: 'robot',
})

export const ProjectSettings = Object.freeze({
  HARDWARE_SETUP_SCREENS_NUM: 'hardware_setup.screens.num',
  HARDWARE_SETUP_SCREENS_PANELS: 'hardware_setup.screens.panels',
  HARDWARE_SETUP_SCREENS_ORIENTATION: 'hardware_setup.screens.orientation',
})

export const HardwareSetupViewPanelType = createKeyEnum({
  STREAMING: { key: 'streaming', text: 'Streaming' },
  NOT_SET: { key: 'not_set', text: 'Not set' },
  HARDWARE_SETUP_INFO: { key: 'hardware_setup_info', text: 'Hardware Setup Info' },
  CLI: { key: 'cli', text: 'Command Line Interface' },
  TEST_1: { key: 'test_1', text: 'Test Panel 1' },
  TEST_2: { key: 'test_2', text: 'Test Panel 2' },
  LIVE_TESTS_LOGS: { key: 'live_tests_logs', text: 'Live Tests Logs' },
  POWER_CONTROL: { key: 'power_control', text: 'PowerControl' },
})

// todo: move to services
export const ServerEvent = Object.freeze({
  NODE_REGISTRED: 'node.register',
  NODE_UNREGISTRED: 'node.unregister',
  NODE_UPDATED: 'node.update',
  NODE_DELETED: 'node.delete',
  NODE_CREATED: 'node.created',
  HARDWARE_SETUP_REGISTRED: 'hardware_setup.register',
  HARDWARE_SETUP_UNREGISTRED: 'hardware_setup.unregister',
  HARDWARE_SETUP_UPDATED: 'hardware_setup.update',
  HARDWARE_SETUP_DELETED: 'hardware_setup.delete',
  HARDWARE_SETUP_CREATED: 'hardware_setup.created',
  TASK_CREATED: 'task.create',
  TASK_DELETED: 'task.delete',
  TASK_UPDATED: 'task.update',
  TASK_STEP_CREATED: 'task.step.create',
  TASK_STEP_UPDATED: 'task.step.update',
  TASK_STEP_DELETED: 'task.step.delete',
  TASK_STATUS_CREATED: 'task.status.create',
  TASK_STATUS_UPDATED: 'task.status.update',
  TASK_STATUS_DELETED: 'task.status.delete',
  TASK_PACKAGE_CREATED: 'task.package.create',
  TASK_PACKAGE_UPDATED: 'task.package.update',
  TASK_PACKAGE_DELETED: 'task.package.delete',
  BOOKING_CREATED: 'booking.create',
  BOOKING_UPDATED: 'booking.update',
  BOOKING_DELETED: 'booking.delete',
  CAPABILITY_CREATED: 'capability.create',
  CAPABILITY_UPDATED: 'capability.update',
  CAPABILITY_DELETED: 'capability.delete',
  CAPABILITY_INSTANCE_CREATED: 'capability.instance.create',
  CAPABILITY_INSTANCE_UPDATED: 'capability.instance.update',
  CAPABILITY_INSTANCE_DELETED: 'capability.instance.delete',
  DEVICE_CREATED: 'hardware_setup.device.create',
  DEVICE_UPDATED: 'hardware_setup.device.update',
  DEVICE_DELETED: 'hardware_setup.device.delete',
  TESTS_CONFIG_CREATED: 'tests.config.create',
  TESTS_CONFIG_UPDATED: 'tests.config.update',
  TESTS_CONFIG_DELETED: 'tests.config.delete',
  PROJECT_SETTINGS_CREATED: 'project.settings.create',
  PROJECT_SETTINGS_UPDATED: 'project.settings.update',
  PROJECT_SETTINGS_DELETED: 'project.settings.delete',
  PROJECT_SETTINGS_VALUES_CREATED: 'project.settings.values.create',
  PROJECT_SETTINGS_VALUES_UPDATED: 'project.settings.values.update',
  PROJECT_SETTINGS_VALUES_DELETED: 'project.settings.values.delete',
  NODE_POWER_CONTROL_MODE_UPDATED: 'node.power_control.mode.update',
})

export const ADBKeycodes = Object.freeze({
  Home: 3,
  Back: 4,
  AppSwitch: 187,
  VolumeUp: 24,
  VolumeDown: 25,
  Power: 26,

  Digit0: 7,
  Digit1: 8,
  Digit2: 9,
  Digit3: 10,
  Digit4: 11,
  Digit5: 12,
  Digit6: 13,
  Digit7: 14,
  Digit8: 15,
  Digit9: 16,
  Numpad0: 7,
  Numpad1: 8,
  Numpad2: 9,
  Numpad3: 10,
  Numpad4: 11,
  Numpad5: 12,
  Numpad6: 13,
  Numpad7: 14,
  Numpad8: 15,
  Numpad9: 16,
  Star: 17,
  Pound: 18,
  KeyA: 29,
  KeyB: 30,
  KeyC: 31,
  KeyD: 32,
  KeyE: 33,
  KeyF: 34,
  KeyG: 35,
  KeyH: 36,
  KeyI: 37,
  KeyJ: 38,
  KeyK: 39,
  KeyL: 40,
  KeyM: 41,
  KeyN: 42,
  KeyO: 43,
  KeyP: 44,
  KeyQ: 45,
  KeyR: 46,
  KeyS: 47,
  KeyT: 48,
  KeyU: 49,
  KeyV: 50,
  KeyW: 51,
  KeyX: 52,
  KeyY: 53,
  KeyZ: 54,
  Comma: 55,
  Period: 56,

  AltLeft: 57,
  AltRight: 58,
  ShiftLeft: 59,
  ShiftRight: 60,
  Tab: 61,
  Space: 62,
  Enter: 66,
  Delete: 67,
  Backspace: 67,
  Minus: 69,
  Equal: 70,
  BracketLeft: 71,
  BracketRight: 72,
  Backslash: 73,
  Semicolon: 74,
  Quote: 75,
  Slash: 76,
  CapsLock: 115,
})

export const TestStatuses = Object.freeze({
  PASSED: { key: 'Passed', class: 'bg-success', text: 'Passed' },
  FAILURE,
  ERROR,
  SKIPPED: { key: 'Skipped', class: 'bg-cardDark', text: 'Skipped' },
})

export const UI_RELATED_CAPABILITIES = Object.freeze({
  BUILD: 'Build',
  SERVER_INTERNAL: 'tests',
  ENVIRONMENT_TYPE: 'environment type',
})

export const DEVICE_TYPES = Object.freeze({
  MAIN: 'Main',
  ADDITIONAL: 'Additional',
})

export default {
  NodeStatus,
  DeviceStatus,
  ActivityStatus,
  TaskStatus,
  TaskActiveStatuses,
  TaskFinalStatuses,
  TaskStepStatus,
  TaskPackageType,
  ComparisonFilters,
  ServerEvent,
  ADBKeycodes,
  TestStatuses,
}
